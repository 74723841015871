<template>
  <div id="app">
    <NavigationBar v-if="!$route.meta.hideNavbarAndFooter"/>
    <router-view/>
    <ScrollToTop/>
  </div>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
import ScrollToTop from '@/components/ScrollToTop.vue';

export default {
  name: 'App',
  components: {
    NavigationBar,
    ScrollToTop
  }
}
</script>
