import { render, staticRenderFns } from "./ScrollToTop.vue?vue&type=template&id=38d82a63&scoped=true"
import script from "./ScrollToTop.vue?vue&type=script&lang=js"
export * from "./ScrollToTop.vue?vue&type=script&lang=js"
import style0 from "./ScrollToTop.vue?vue&type=style&index=0&id=38d82a63&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d82a63",
  null
  
)

export default component.exports