<template>
    <div v-if="isVisible" class="scroll-to-top button is-medium is-danger has-text-grey-darker" @click="scrollToTop">
        <span class="icon">
            <font-awesome-icon icon="arrow-up" />
        </span>
    </div>
</template>

<style scoped lang="scss">

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: -2px 2px 4px rgba(0,0,0,0.3);
  &.is-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
}
</style>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.isVisible = window.scrollY > 300; // Show button after scrolling down 300px
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>