<template>
    <nav class="navbar is-sticky is-spaced is-link" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
                <a class="navbar-item is-unselectable has-text-weight-bold" @click="scrollToTop()">
                    <div class="navbar-logo">
                        <span class="has-text-shadow">ngmlab</span><span class="has-text-danger"> &#x2022; </span><span class="ngmlab-logo has-text-link has-text-grey-darker">DEV</span>
                    </div>
                </a>
                <a class="navbar-burger has-text-danger" role="button" aria-label="menu" aria-expanded="false" data-target="navigation">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navigation" class="navbar-menu has-text-centered">
                <div class="navbar-start ">

                </div>
                <div class="navbar-end is-unselectable">

                    <div class="navbar-item">
                        <a class="navbar-item is-uppercase  has-text-weight-bold" @click="scrollTo('about')">
                            About
                        </a>
                        <a class="navbar-item is-uppercase  has-text-weight-bold" @click="scrollTo('my-work')">
                            My Work
                        </a>
                        <!-- <a class="navbar-item is-uppercase  has-text-weight-bold" @click="scrollTo('contact')">
                            Contact
                        </a> -->
                        <div class="buttons">

                            <a href="mailto:info@ngmlab.dev" class="button is-small is-danger has-text-link has-text-weight-bold is-fullwidth">
                                <span class="icon">
                                    <font-awesome-icon icon="envelope" />
                                </span>
                                <span>info@ngmlab.dev</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
$blue:#11182B;
$red: #FFDB00;

@use "bulma/sass/utilities/mixins";

@include mixins.until(1023px) {
    .navbar {
        &.is-spaced {
            padding: var(--bulma-navbar-padding-vertical) 0 !important;
        }
    }
    .navbar-menu{
        opacity: 0;
        &.is-active {
            position: absolute;
            animation: slide-in-from-top 0.5s forwards;
            
            width: 12rem;
            right: 0;
            border-radius: 0.75rem;
            box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
        }

    }
}
@keyframes slide-in-from-top {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.navbar {

    /* Navbar logo is hidden initially */
     .navbar-logo {
        font-size: 0.5rem;
        opacity: 0;
        transition: all .7s ease;
        &.visible {
            font-size: 1.5rem;
            display: block;
            opacity: 1;
        }
    }

    &.is-sticky {
        position: sticky;
        top: 0;
        z-index: 30;
        // box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    }
    .navbar-brand {
        .navbar-item {
            font-size: 1.5rem;
            span {
                line-height: 1.5rem;
            }
            &:hover{
                color: white !important;
                background-color: transparent !important;
            }
            &:active{
                color: white !important;
                background-color: transparent !important;
            }
            .ngmlab-logo {
                transition: all 1.5s ease;
                background-color: $red;
                color: $blue;
                padding: 0.15rem 0.3rem;
                border-radius: 0.25rem;
            }
        }
    }

    .navbar-menu {
    
        .navbar-item {
            font-size: 0.75rem;
        }

        .navbar-item {
            border-radius: 0.5rem;
            &:hover{
                color: $red !important;
                // background-color: transparent !important;
            }
            &:active, &:focus{
                // background-color: transparent !important;
            }       
        }
    }
    .has-text-shadow {
        background-clip: text;
        text-shadow: -2px 1px 2px rgba(52,48,49,1);
    }

}

</style>

<script>
export default {
    name: 'NavigationBar',
    mounted() {
        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {
                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
            });
        });

        // Close the navbar when a link is clicked
        const navbarLinks = document.querySelectorAll('.navbar-item');
        navbarLinks.forEach(function (navbarLink) {
            navbarLink.addEventListener('click', function () {
                const navbarMenu = document.getElementById('navigation');
                const navbarBurger = document.querySelector('.navbar-burger');
                if (navbarMenu.classList.contains('is-active')) {
                    navbarMenu.classList.remove('is-active');
                    navbarBurger.classList.remove('is-active');
                }
            });
        });
    },
    data() {
        return {
            navBarState: false,
        };
    },
    computed: {
        isNavbarActive () {
            return this.getNavBarState;
        }
    },
    methods: {
        changeLanguage(locale) {
            localStorage.setItem('locale', locale);
            this.$i18n.locale = locale; // Change the locale dynamically
        },
        scrollTo(elementId) {

            const element = document.getElementById(elementId);
            let yOffset = -document.querySelector('.navbar').offsetHeight; // Get the navbar height (adjust the selector as needed)
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            
            if (element) {
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
                // element.scrollIntoView({
                //     top: yOffset,
                //     behavior: 'smooth',
                //     // block: 'start'
                // });
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>