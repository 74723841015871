import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    en: {
        resume: {
            title: 'Resume',
            name: 'Ioannis',
            surname: 'Mathioudakis',
            location: 'Athens, Greece',
            about: {
              title: 'About Me',
              content: [
                'I am a dedicated and versatile IT professional with a strong background in both software development and enterprise security solutions.',
                'With extensive experience in full stack web development, custom integrations, and Public Key Infrastructure (PKI) services, I thrive in complex technical environments. My passion lies in solving critical business challenges through innovative development, scripting, and system optimization.',
                'I am always eager to learn and apply new technologies, ensuring that my solutions are not only effective but also secure and scalable.'
              ]
            },
            summary:{
              title: 'Summary',
              tagline: 'Experienced Full Stack Developer and Professional Services Engineer with over {yearsOfExperience} years in IT, specializing in full stack web development, custom API integrations, enterprise solutions, and Public Key Infrastructure (PKI) services.',
              content: [          
                'Expertise in setting up virtualized environments, clustered databases, and security solutions. Strong proficiency in C#, JavaScript, and Linux-based systems, with hands-on experience in SAP BusinessOne, SAP HANA, and ERP systems.',
                'Adept at scripting and troubleshooting complex systems, ensuring seamless implementation and issue resolution.'
              ]
            },
            experience: {
              title: 'Work Experience',
              data: [
                {
                  title: 'Professional Services Engineer',
                  company: 'ADACOM S.A.',
                  location: 'Athens, Greece',
                  date: 'Sep 2021 - Present',
                  description: 'Implemented and supported Public Key Infrastructure (PKI) services for enterprise clients. Developed custom integrations for secure authentication and encryption. Provided technical training and support to customers on security best practices. Collaborated with sales and product teams to deliver comprehensive security solutions.',
                  tasks: [
                    'Manage the deployment and configuration of Public Key Infrastructure (PKI) solutions, including Entrust PKI and ADSS Server, on virtualized environments.',
                    'Install and configure clustered database environments such as PostgreSQL and MySQL for high availability.',
                    'Implemented Redis Sentinel for high availability and automatic failover of Redis instances, ensuring data resilience and minimal downtime for key enterprise applications.',
                    'Setup and manage virtual machines (VMs), primarily utilizing RHEL and Rocky Linux servers.',
                    'Implement custom Bash scripts to troubleshoot and resolve issues with vendor solutions, improving system stability and efficiency.',
                    'Developed a custom PostgreSQL SQL script for OCSP connections through Entrust PKI and ADSS Server, streamlining certificate validation processes.',
                    'Configured and integrated Hardware Security Modules (HSMs), such as nCipher and Utimaco, for secure key management.',
                    'Collaborate closely with clients to identify pain points and deliver tailored solutions that enhance system security and reliability.',
                  ]
                },
                {
                  title: 'Full Stack Developer',
                  company: 'Intale S.A.',
                  location: 'Athens, Greece',
                  date: 'May 2021 - Sep 2021',
                  description: 'Contributed to the development of custom web applications and APIs for enterprise clients. Worked with cross-functional teams to design and implement scalable solutions. Participated in code reviews and supported junior developers with technical guidance. Assisted in managing the full software development lifecycle, including requirements gathering, deployment, and maintenance.',
                  tasks:[
                    'Developed and maintained features for the Intale ERP, enhancing kiosk management efficiency.',
                    'Delivered end-to-end web development using C#, Angular, and Node.js.',
                    'Optimized performance and scalability of the ERP platform, improving client satisfaction.',
                    'Collaborated closely with product managers and designers to implement new features and fix bugs.',
                  ]
                },
                {
                  title: 'Software Developer',
                  company: 'd.d. Synergy Hellas',
                  location: 'Athens, Greece',
                  date: 'Nov 2017 - May 2021',
                  description: 'Served as an SAP BusinessOne Developer, specializing in creating custom solutions, integrating third-party applications, and developing web applications. Worked extensively with SAP HANA, DI API, and oData queries to enhance and streamline ERP processes. The role involved leveraging SAP BusinessOne and various tools to ensure smooth system operations, optimize performance, and deliver tailored business solutions.',
                  tasks: [
                    'Designed and implemented custom solutions using DI API extensions to enhance SAP BusinessOne functionalities.',
                    'Developed automation scripts and programs for routine business processes, improving efficiency and reducing manual input.',
                    'Integrated SAP BusinessOne with third-party systems (e.g., WooCommerce, CRM) using REST and SOAP APIs for seamless data synchronization.',
                    'Ensured data synchronization across systems, maintaining consistency and improving workflow across the business operations.',
                    'Performed complex oData queries on SAP HANA for data extraction and reporting, ensuring optimized database performance.',
                    'Developed web applications interfacing with SAP BusinessOne to provide real-time data access and improved reporting.',
                    'Leveraged modern web development frameworks and technologies to build user-friendly and responsive interfaces.',
                    'Provided technical support for custom solutions and integrations, ensuring system stability and resolving issues promptly.',
                    'Worked closely with cross-functional teams to understand business requirements and translate them into technical solutions.',
                  ]
                },
                {
                  title: 'Junior Software Developer',
                  company: 'Nubis S.A.',
                  location: 'Athens, Greece',
                  date: 'April 2016 - Nov 2017',
                  description: "Worked as a Junior Software Developer on Nubis S.A.'s cloud-based ERP and CRM platform. Maintained the platform's codebase, contributed to the database migration process, and later participated in rewriting the engine using modern web technologies, including NodeJS, JavaScript, jQuery and SASS.",
                  tasks: [
                    'Maintained and updated the ERP/CRM platform codebase, resolving bugs and optimizing performance.',
                    'Created a C# tool to automate database migration from customized MSSQL databases, ensuring smooth data transitions.',
                    'Collaborated with the team to rewrite the platform’s engine using NodeJS, JavaScript, jQuery, and SASS, enhancing scalability and performance.',
                    'Worked across both front-end and back-end tasks to improve platform functionality and user experience.'
                  ]
                }
              ]
            },
            skills: [
              {
                title: 'Programming Languages',
                list: 'C#, JavaScript, HTML, CSS, SASS, NodeJS, Bash'
              },
              {
                title: 'Frameworks & Libraries',
                list: 'jQuery, Bootstrap, .NET Core, Express.js, Vue.js, .NET, KendoUI, BULMA, Custom JS Framework (for Nubis Cloud Engine), SAP DI API, SAP Fiori, SAP UI5'
              },
              {
                title: 'Databases',
                list: 'MSSQL, MySQL, PostgreSQL, SAP HANA'
              },
              {
                title: 'Tools & Platforms',
                list: 'Git, Docker, Entrust PKI, ADSS Server, SAP BusinessOne'
              },
              {
                title: 'Operating Systems',
                list: 'Windows Server, Linux (RHEL, Rocky, Debian, Arch)'
              },
              {
                title: 'Other',
                list: 'API Integration, Third-party Services, Web Services, CI/CD pipelines, ERP Systems, CRM Systems, HSMs'
              }
            ],
            personalProjects: [
              {
                title: 'Linux Homelab Administrator',
                content: 'Actively manage a personal homelab environment with three servers (one cloud-based, two in different physical locations) running on Linux, leveraging Docker for containerized services.'
              },
              {
                title: 'WireGuard VPN',
                content: 'Configured WireGuard VPN to securely interconnect servers across different locations, ensuring encrypted communication and seamless access to internal services.'
              },
              {
                title: 'Internal PKI with HashiCorp Vault',
                content: 'Set up an internal Public Key Infrastructure (PKI) using HashiCorp Vault to manage SSL certificates for secure communications within the homelab.'
              },
              {
                title: 'Mobile Backup Solution',
                content: 'Deployed Nextcloud and Immich as a private backup solution for mobile devices, ensuring data redundancy and security for photos and files across all devices.'
              }
            ]
          }
    }
};

// Check if a locale is saved in localStorage, otherwise default to 'el'
const savedLocale = localStorage.getItem('locale') || 'en';

// Create the Vue I18n instance
const i18n = new VueI18n({
  locale: savedLocale, // Set default locale
  fallbackLocale: 'en', // Set fallback locale
  messages, // Set locale messages
});

export default i18n;
